import React, { useState, useEffect } from "react";
import { PostData as OMSPostData } from "../../../services/PostData";
import { PostData } from "../../../services/Api.service";
import zipcode_to_timezone from "zipcode-to-timezone";
import { Button, Modal, Col, Row, Table, Label, UncontrolledTooltip } from "reactstrap";
import "./style.css";
import * as moment from "moment";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Validate } from "../../../services/Validation";
import { loggedInUser, checkRoles } from "components/Common/Helpers.js"
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "components/Common/Spinner.js";
import eventBus from "../Common/EventBus";
import { IAT_RESCHEDULED, IO_COMPLETED, IO_ON_HOLD, IO_RECEIVED } from "./Constants";
import PaymentForm from "../Common/PaymentForm.js";
import ReportOrderOriginalSource from "../Common/ReportOrderOriginalSource.js";
import { sendExceptionEmail, formatDate } from "components/Common/Helpers";
import DateFormat from "components/Common/DateFormat";
import DrawLineItem from "../Inspection/DrawLineItem";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setErrorIndexData } from "../InspectionOrderIntake/inspectionOrderIntake.reducer";
import RenderActionButton from "./ActionsButtons/RenderActionButton";
import DragAndDropOrUpload from "./File-Upload/DragAndDropOrUpload";
import Error from "components/Ui/Error";

moment.tz.setDefault("America/phoenix");
let myFormRef;

const InspectionHistoryModal = ({ onReportData, propButtonOptions }) => {

    const userData = loggedInUser();
    const [isProcess, setProcess] = useState(false);
    let [inspection_action_types, set_inspection_action_types] = useState([]);
    let [fields, setFields] = useState({});
    let [selectedFile, setSelectedFile] = useState([]);
    let [selectedRow, setSelectedRow] = useState([]);

    let [selectedCoverPhotos, setSelectedCoverPhotos] = useState([]);
    let [isOpenInspectionModal, setOpenInspectionModal] = useState(false);
    let [scheduled_date_error, set_scheduled_date_error] = useState(false);
    let [follow_up_date_error, set_follow_up_date_error] = useState(false);
    let [modalLabel, setModalLabel] = useState("");
    let [scheduled_date_error_message, set_scheduled_date_error_message] = useState(10485760); // 10mb    
    let [follow_up_date_error_message, set_follow_up_date_error_message] = useState("");
    let [inspectionHistory, setInspectionHistory] = useState([]);
    let [min_follow_up_date] = useState(new Date());
    let [activeInspectionDrawItems, setActiveInspectionDrawItems] = useState([]);
    let [inspectionOrderData, setInspectionOrderData] = useState({});
    let [update, setUpdate] = useState(moment());

    let [isOpenPayTripChargeModal, setIsOpenPayTripChargeModal] = useState(false);
    let [isOpenPaymentSourceModal, setIsOpenPaymentSourceModal] = useState({ current: false });
    let [clientId, setClientId] = useState();
    let [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    let [paymentError, setPaymentError] = useState(null);
    let [inspectionTripCharge, setInspectionTripCharge] = useState(0);
    let [defaultSourceChecked, setDefaultSourceChecked] = useState(false);
    let [ourReportPreviewed, setOurReportPreviewed] = useState(false);
    let [previewedReport, setPreviewedReport] = useState(null);
    let [tz] = useState(moment.tz.guess());
    let [finalize, setFinalize] = useState(false);
    let [drawManageUrl, setDrawManageUrl] = useState(null);
    const [customError, setCustomError] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => { }, [update]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = (e) => {
        e.preventDefault();

        setOurReportPreviewed(false);
        setFinalize(false);
        set_scheduled_date_error(false);
        set_follow_up_date_error(false);
        setSelectedCoverPhotos([]);
        setSelectedFile([]);
        setSelectedRow([]);
        setFields({});
        setPaymentError(null);
        setDefaultSourceChecked(false);
        setClientId(onReportData.company_id);
        handleOrderInspectionStatus(onReportData);
        setCustomError("");
    };

    // Handle report order status
    let handleOrderInspectionStatus = async (data) => {

        if (data.inspection_order_id || data.report_order_id) {
            setFields({});
            setSelectedFile([]);
            setSelectedCoverPhotos([]);
            setCustomError("")
            setProcess(true);
            let inspection_history = [];

            try {
                let orderType = data.inspection_order_id ? "1" : "2";
                let inspectionId = data.inspection_order_id ? data.inspection_order_id : data.report_order_id;
                let drawUrl = `manage-draw-line-items/${inspectionId}/${orderType}`;
                setDrawManageUrl(drawUrl);
                const actionTypesResp = await OMSPostData("list/inspection_action_types", {});
                set_inspection_action_types(actionTypesResp.data.data);
                setSelectedRow(onReportData);
                // Set Stripe payment
                let tripChargeTemp = onReportData.trip_charge ? onReportData.trip_charge : 0;
                setInspectionTripCharge(tripChargeTemp);
                // handleOrderInspectionStatus(onReportData);


                let result = await PostData("inspection/inspection-history", {
                    inspection_order_id: data.inspection_order_id,
                    report_order_id: data.report_order_id
                });

                let responseJson = result.data;

                setInspectionOrderData(responseJson.inspectionOrderData)

                Object.keys(responseJson.rows).forEach(index => {
                    let finalOrderObj = {};
                    let inspection_action_type = responseJson.rows[index].inspection_action_type;
                    let inspection_order = responseJson.rows[index].inspection_order;
                    let user = responseJson.rows[index].user;

                    finalOrderObj.action_id = responseJson.rows[index].action_id;
                    finalOrderObj.action_type_id = responseJson.rows[index].action_type_id;

                    finalOrderObj.action_user_id = responseJson.rows[index].action_user_id;
                    finalOrderObj.datetime = responseJson.rows[index].datetime;
                    finalOrderObj.id = responseJson.rows[index].id;

                    finalOrderObj.inspection_action_type = inspection_action_type;
                    finalOrderObj.inspection_order = inspection_order;
                    finalOrderObj.inspection_order_id = responseJson.rows[index].inspection_order_id;

                    finalOrderObj.other_data = JSON.parse(responseJson.rows[index].other_data);
                    for (let i in finalOrderObj.other_data) {
                        if (finalOrderObj.other_data.hasOwnProperty(i)) {
                            if (i === 'schedule_date') {
                                let propertyTz = zipcode_to_timezone.lookup(result.data.propertyData.postal_code);

                                if (propertyTz) {
                                    finalOrderObj.schedule_date = moment(finalOrderObj.other_data[i]).tz(propertyTz).format('MMM D, h:mm A z');
                                } else {
                                    finalOrderObj.schedule_date = formatDate(null, finalOrderObj.other_data[i], 'MMM D, h:mm A z')
                                }
                            }
                        }
                    }
                    finalOrderObj.question_comment = responseJson.rows[index].question_comment;
                    finalOrderObj.report_id = responseJson.rows[index].report_id;
                    finalOrderObj.user = user;
                    inspection_history.push(finalOrderObj);
                });
                setModalLabel(data.address);
                setInspectionHistory(inspection_history);
                setProcess(false);
                setOpenInspectionModal(true);
                setUpdate(moment());
            } catch (error) {
                toast['error'](error.message);
                setUpdate(moment());
                await sendExceptionEmail(error, { componentName: 'InspectionHistoryModal', functionName: 'handleOrderInspectionStatus', data: {} });
                setProcess(false);
            }
        }
    }

    let handleToggleModal = (state) => {
        state = !state;
        setOpenInspectionModal(state);
        setPaymentError(null);
        setActiveInspectionDrawItems([]);
        setUpdate(moment());
    };

    let handleInput = (e) => {
        e.preventDefault();
        // let fields = fields;
        fields[e.target.name] = e.target.value;
        setFields(fields)

        if (!['9', '11'].includes(fields.action_id)) {
            setSelectedFile([]);
            setSelectedCoverPhotos([]);
            setCustomError("");
        }

        setUpdate(moment());
    }

    let handleInputDate = (date, name) => {
        fields[name] = date;
        setFields(fields);
        setUpdate(moment());
    };

    let handleAfterPayment = async () => {
        if (selectedPaymentSource && selectedPaymentSource.payment_source) {

            // Set Stripe payment
            let tripCharge = selectedRow.trip_charge ? selectedRow.trip_charge : 0;

            let params = {
                inspection_order_token: inspectionOrderData.inspection_order_token,
                amount: tripCharge,
                payment_source: selectedPaymentSource.payment_source
            };

            setProcess(true);
            try {
                let result = await PostData("inspection/pay_trip_charge", params);
                setSelectedPaymentSource(null);
                setUpdate(moment());
                if (result.data.payment_status) {
                    saveInspectionData(selectedRow);
                }

            } catch (error) {
                if (error?.errors?.err_code === 'PAYMENT_ERROR' && selectedPaymentSource?.isOriginalSource === true) {
                    setPaymentError(error.message);
                    setSelectedPaymentSource(null);
                } else {
                    toast["error"](error.message);
                }

                await sendExceptionEmail(error, { componentName: 'InspectionHistoryModal', functionName: 'handleAfterPayment', data: {} });
                setUpdate(moment());
                setProcess(false);
            }
        }
    }

    let handleSubmit = async (e, isSendOurReport, isPreviewed) => {
        e.preventDefault();

        let row = selectedRow;
        let params = fields;
        let error = 0;

        let tripCharge = row.trip_charge ? row.trip_charge : 0;

        if (tripCharge > 0 && parseInt(params.action_id) === IAT_RESCHEDULED) {
            setPaymentError(null);
            setIsOpenPayTripChargeModal(true);
        } else {

            let validationResp = '';

            if (['5'].includes(fields.action_id)) {
                validationResp = Validate(fields.scheduled_date, [['required', true]]);

                if (validationResp.error) {
                    set_scheduled_date_error(true);
                    set_scheduled_date_error_message(validationResp.message);
                    error++;
                }
            }

            if (['13'].includes(fields.action_id)) {

                validationResp = Validate(fields.follow_up_date, [['required', true]]);

                if (validationResp.error) {
                    set_follow_up_date_error(true);
                    set_follow_up_date_error_message(validationResp.message);
                    error++;
                }
            }

            if (error > 0) {
                return false;
            }

            let duplicatesLines = handleDuplicateLineItem(activeInspectionDrawItems);
            const values = myFormRef.getValues();
            let { isValid } = await myFormRef.validateAll(values, false);
            if (!isValid || duplicatesLines > 0) {
                return false
            }


            if (params.follow_up_date) {
                params.follow_up_date_formatted = moment(new Date(params.follow_up_date)).tz(tz).format('YYYY-MM-DD'); // 2016-07-15
            }

            if (params.scheduled_date) {
                params.scheduled_date_formatted = moment(new Date(params.scheduled_date)).tz(tz).format('YYYY-MM-DD HH:mm:ss');
            }

            if (row.report_order_id) {
                params.report_id = row.report_order_id;
            }

            if (row.inspection_order_id) {
                params.inspection_order_id = row.inspection_order_id;
            }

            if (row.property_id) {
                params.property_id = row.property_id;
            }

            if (row.inspection_property_id) {
                params.inspection_property_id = row.inspection_property_id;
            }

            params.user_id = userData.user_id;

            const data = new FormData()

            for (let i = 0; i < selectedFile.length; i++) {
                data.append(`inspection_files`, selectedFile[i])
            }

            for (let i = 0; i < selectedCoverPhotos.length; i++) {
                data.append(`cover_photo_files`, selectedCoverPhotos[i])
            }

            if (activeInspectionDrawItems?.length) {
                data.append("inspection_draw_items", JSON.stringify(activeInspectionDrawItems));
                params.isOurReport = isSendOurReport;
                params.report_files = previewedReport;
            }
            params.isOurReportPreviewed = isPreviewed;

            for (var key in params) {
                data.append(key, params[key]);
            }

            if ([IO_COMPLETED, IO_RECEIVED].includes(parseInt(fields.action_id)) && onReportData.isStandalone !== '1') {
                if (selectedCoverPhotos.length === 0 || selectedFile.length === 0) {
                    setProcess(false);
                    setCustomError("Both File is required");
                    return false;
                }
            }

            setProcess(true);
            try {
                let result = await PostData("inspection/save_inspection_data", data);
                toast["success"](result.message);
                if (result.data.report_file && isPreviewed === true) {
                    setPreviewedReport(result.data.report_file);
                    setOurReportPreviewed(isPreviewed);
                    window.open(result.data.report_file, '_blank');
                }
                if (isPreviewed === false) {
                    eventBus.dispatch("report_order_status_updated", { client_id: onReportData.company_id });
                    eventBus.dispatch("inspection_order_status_updated", { client_id: onReportData.company_id });

                    handleToggleModal(isOpenInspectionModal);
                }
                let updatedItems = updateCompletionPercentages(activeInspectionDrawItems);
                setActiveInspectionDrawItems(updatedItems);

                setUpdate(moment());
                setProcess(false);

            } catch (error) {
                toast["error"](error.message);
                setUpdate(moment());
                await sendExceptionEmail(error, { componentName: 'InspectionHistoryModal', functionName: 'handleSubmit', data: {} });
                setProcess(false);
            };
        }
    };

    let handleFinalize = async (saveOnly = false) => {
        try {
            const updatedData = activeInspectionDrawItems.map(item => ({
                ...item,
                assignedImages: item.assignedImages.map(({ id, label }) => ({ id, label }))
            }));

            let requestBody = {
                draw_items: updatedData,
                inspection_order_id: onReportData.inspection_order_id
            }
            let duplicatesLines = handleDuplicateLineItem(activeInspectionDrawItems);
            const values = myFormRef.getValues();
            let { isValid } = await myFormRef.validateAll(values, false);
            if (!isValid || duplicatesLines > 0) {
                return false
            }

            setProcess(true);
            const actionTypesResp = await OMSPostData("inspection/finalize-inspection-history-data", requestBody);

            let updatedItems = updateCompletionPercentages(activeInspectionDrawItems);
            setActiveInspectionDrawItems(updatedItems);

            toast['success'](actionTypesResp.data.message);
            setProcess(false);
            if (!saveOnly) {
                setFinalize(true);
            }
            setUpdate(moment())
        } catch (error) {
            toast['error'](error.message);
            setUpdate(moment());
            await sendExceptionEmail(error, { componentName: 'InspectionHistoryModal', functionName: 'handleFinalize', data: {} });
            setProcess(false);
        }
    }

    let handleDuplicateLineItem = (lineItems = null) => {
        let duplicates = [];
        if (lineItems) {
            duplicates = findErrorIndexes(lineItems);
        } else {
            duplicates = findErrorIndexes(activeInspectionDrawItems);
        }
        if (duplicates.length) {
            toast['error']("Please review the highlighted row and rectify any errors");
        }
        dispatch(setErrorIndexData({ value: duplicates }))
        return duplicates.length;
    }

    let findErrorIndexes = (array) => {
        const errorIndexes = [];
        array.forEach((item, index) => {
            let our_completion_percent = item.new_our_completion_percent || item.our_qc_completion_percent || 0;
            if (item.active && parseFloat(item.previous_completion_percentage) > parseFloat(our_completion_percent)) {
                errorIndexes.push(index);
            }
        });
        return errorIndexes;
    }

    let updateCompletionPercentages = (items) => {
        return items.map(item => {
            if (item.new_our_completion_percent !== undefined) {
                if (item.new_our_completion_percent !== item.our_qc_completion_percent) {
                    item.our_qc_completion_percent = item.new_our_completion_percent;
                }
            }
            return item;
        });
    }

    let saveInspectionData = async (row) => {

        setIsOpenPayTripChargeModal(false);
        const data = new FormData()

        if (row.report_order_id) {
            data.append('report_id', row.report_order_id);
        }
        if (row.inspection_order_id) {
            data.append('inspection_order_id', row.inspection_order_id);
        }

        if (row.property_id) {
            data.append('property_id', row.property_id);
        }

        if (row.inspection_property_id) {
            data.append('inspection_property_id', row.inspection_property_id);
        }

        data.append('user_id', userData.user_id);
        data.append('action_id', IAT_RESCHEDULED);

        try {

            let result = await PostData("inspection/save_inspection_data", data);

            toast["success"](result.message);
            let updatedItems = updateCompletionPercentages(activeInspectionDrawItems);
            setActiveInspectionDrawItems(updatedItems);

            eventBus.dispatch("report_order_status_updated", { client_id: onReportData.company_id });
            eventBus.dispatch("inspection_order_status_updated", { client_id: onReportData.company_id });

            handleToggleModal(isOpenInspectionModal);
            setProcess(false);
            setUpdate(moment());

        } catch (error) {
            toast["error"](error.message);
            setUpdate(moment());
            await sendExceptionEmail(error, { componentName: 'InspectionHistoryModal', functionName: 'saveInspectionData', data: {} });
            setProcess(false);
        };
    }

    const onDropPropertyFile = (files) => {
        setSelectedFile(files?.selectedFiles);
        setUpdate(moment());
    };

    const onDropCoverPhoto = (files) => {
        setSelectedCoverPhotos(files?.selectedFiles);
        setUpdate(moment());
    };

    let deleteFile = (e, fileType, index) => {
        e.preventDefault();
        if (fileType === 'selectedFile') {
            selectedFile.splice(index, 1);
            setSelectedFile(selectedFile);
        } else if (fileType === 'selectedCoverPhotos') {
            selectedCoverPhotos.splice(index, 1);
            setSelectedCoverPhotos(selectedCoverPhotos);
        }
        setCustomError("");
        setUpdate(moment());
    };

    let propertyFiles = selectedFile.map((file, i) => (
        <li key={file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, 'selectedFile', i)}></i> {file.name} - {file.size} bytes
        </li>
    ));

    let coverPhotos = selectedCoverPhotos.map((file, i) => (
        <li key={file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, 'selectedCoverPhotos', i)}></i> {file.name} - {file.size} bytes
        </li>
    ));

    let openPaymentMethodSelector = () => {
        setIsOpenPaymentSourceModal({ current: true });
    };

    let handlePaymentSourceCb = (result) => {
        if (result) {
            setSelectedPaymentSource(result);
            setUpdate(moment());
        }
    };

    let onReportOrderOriginalSourceCB = (result) => {
        setSelectedPaymentSource(result);
        setDefaultSourceChecked(true);
        setUpdate(moment());
    };

    let onSetActiveLineItemData = (data) => {
        if (data) {
            setActiveInspectionDrawItems(data.activeInspectionDrawItems)
        }
    }

    return (
        <>
            {propButtonOptions?.showLink ?
                <>
                    <Link className="custom-link" id={"inspection_" + onReportData.inspection_order_id} to="#" onClick={(e) => handleActionClick(e)}>{propButtonOptions.linkText}</Link>
                    <UncontrolledTooltip delay={0} placement="auto" target={"inspection_" + onReportData.inspection_order_id} > Inspection </UncontrolledTooltip>
                </>
                :
                <RenderActionButton propButtonTitle="Inspection" propButtonOptions={propButtonOptions} propOnClickEvent={(e) => handleActionClick(e)} />
            }
            <Modal className={`${['9', '11'].includes(fields.action_id) && onReportData.isStandalone === '1' ? 'modal-dialog' : 'modal-dialog-centered'} modal-xl modal-draw-inspection-form`} style={{ maxHeight: "90%", maxWidth: "90%" }} isOpen={isOpenInspectionModal} toggle={() => handleToggleModal(isOpenInspectionModal)}>
                <div style={{ maxHeight: "100%", maxWidth: "100%", overflowY: "auto", overflowX: "hidden" }}>
                    <div className="modal-header modal-header-colored">
                        <h2 className="text-center modal-title w-100 ">
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" className="text-center">
                                    {modalLabel}
                                </Col>
                            </Row>
                        </h2>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenInspectionModal)}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Spinner isShow={isProcess} />
                        <div className="text-left">
                            <AvForm ref={(el) => myFormRef = el} >
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="12">
                                        <Col xs="5" sm="5" md="5" lg="5" className="float-left">
                                            <h2> Log a new action </h2>
                                        </Col>
                                        {parseInt(inspectionOrderData.inspection_type?.draw_inspection) === 1 &&
                                            <Col xs="6" sm="6" md="6" lg="6" className="float-right">
                                                <Link target="_blank" to={drawManageUrl} className="float-right"><span> <i className="fas fa-edit"></i> Manage Draw Line Items </span></Link>
                                            </Col>
                                        }
                                    </Col>
                                    <Col className={['9', '11'].includes(fields.action_id) ? "form-group-margin-reset" : ""} xs="12" sm="12" md={['9', '11'].includes(fields.action_id) && onReportData.isStandalone === '1' ? "2" : "4"} lg={['9', '11'].includes(fields.action_id) && onReportData.isStandalone === '1' ? "2" : "4"}>
                                        <AvGroup className="auto-complete-form-control">
                                            <AvField type="select" name="action_id"
                                                onChange={(event) => handleInput(event)}
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "This field is required."
                                                    }
                                                }}>
                                                <option value="">Select Type</option>
                                                {inspection_action_types.filter(e => (onReportData.isStandalone === '1' ? e.status_id !== IO_ON_HOLD : e)).map((inspection_action_type) =>
                                                    <option key={inspection_action_type.status_id} value={inspection_action_type.status_id}> {inspection_action_type.status_name}</option>
                                                )}
                                            </AvField>
                                        </AvGroup>
                                    </Col>
                                    <Col className="form-group-margin-reset" xs="12" sm="12" md={['9', '11'].includes(fields.action_id) && onReportData.isStandalone === '1' ? "4" : "8"} lg={['9', '11'].includes(fields.action_id) && onReportData.isStandalone === '1' ? "4" : "8"}>
                                        <AvGroup className="mb--3">
                                            <AvField
                                                // bsSize="sm"
                                                name="question_comment"
                                                onChange={(event) => handleInput(event)}
                                                className="form-control"
                                                placeholder="Type Notes"
                                                type="text"
                                                validate={{
                                                    maxLength: {
                                                        value: 255,
                                                        errorMessage: 'Max length for note is 255 characters.'
                                                    }
                                                }}
                                            />
                                        </AvGroup>
                                    </Col>
                                    {['9', '11'].includes(fields.action_id) && onReportData.isStandalone === '1' ?
                                        <>
                                            <Col xs="12" sm="12" md="3" lg="3" className="margin-top-first-row">
                                                <DragAndDropOrUpload label="Upload Inspection" accept="pdf" fileUploadCB={(files) => onDropPropertyFile(files)} maxFileSize={20} maxFiles={1} selectedFiles={propertyFiles} />
                                            </Col>
                                            <Col xs="12" sm="12" md="3" lg="3" className="margin-top-first-row">
                                                <DragAndDropOrUpload label="Cover Photo" accept="jpg,jpeg,png" fileUploadCB={(files) => onDropCoverPhoto(files)} maxFileSize={20} maxFiles={1} selectedFiles={coverPhotos} />
                                            </Col>
                                        </>
                                        : null}
                                    {['5'].includes(fields.action_id) &&
                                        <Col xs="12" sm="12" md="3" lg="3">
                                            <AvGroup>
                                                <Label> Scheduled Date </Label>
                                                <DatePicker
                                                    required
                                                    placeholderText="Select Scheduled Date"
                                                    className="form-control"
                                                    showTimeInput
                                                    dateFormat="Pp"
                                                    selected={fields.scheduled_date}
                                                    onChange={(date) => handleInputDate(date, 'scheduled_date')}
                                                />

                                                {scheduled_date_error && <div className="form-error">{scheduled_date_error_message}</div>}
                                            </AvGroup>
                                        </Col>
                                    }
                                    {['13'].includes(fields.action_id) &&
                                        <Col xs="12" sm="12" md="3" lg="3">
                                            <AvGroup>
                                                <Label> Follow Up Date</Label>
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Select Follow up Date"
                                                    selected={fields.follow_up_date}
                                                    onChange={(date) => handleInputDate(date, 'follow_up_date')}
                                                    minDate={min_follow_up_date}
                                                />

                                                {follow_up_date_error && <div className="form-error">{follow_up_date_error_message}</div>}

                                            </AvGroup>
                                        </Col>
                                    }

                                    {['9', '11'].includes(fields.action_id) &&
                                        <>
                                            {onReportData.isStandalone !== '1' &&
                                                <Col xs="12" sm="12" md="4" lg="4" className="mt-3" >
                                                    <DragAndDropOrUpload label="Upload Inspection" accept="pdf" fileUploadCB={(files) => onDropPropertyFile(files)} maxFileSize={20} maxFiles={1} selectedFiles={propertyFiles} />
                                                </Col>
                                            }
                                            {onReportData.isStandalone !== '1' &&
                                                <Col xs="12" sm="12" md="4" lg="4" className="mt-3" >
                                                    <DragAndDropOrUpload label="Cover Photo" accept="jpg,jpeg,png" fileUploadCB={(files) => onDropCoverPhoto(files)} maxFileSize={20} maxFiles={1} selectedFiles={coverPhotos} />
                                                </Col>
                                            }
                                        </>
                                    }
                                </Row>

                                {['9', '11'].includes(fields.action_id) &&
                                    <Row>
                                        {(onReportData.isStandalone === '1' && parseInt(inspectionOrderData?.inspection_type?.draw_inspection) === 1) &&
                                            <>
                                                <DrawLineItem onHandleData={onSetActiveLineItemData} reportData={{ inspection_order_id: onReportData.inspection_order_id ? onReportData.inspection_order_id : onReportData.report_order_id, type: onReportData.inspection_order_id ? "1" : "2" }} />
                                            </>
                                        }
                                    </Row>
                                }

                                <br></br>

                                <Col className="col-md-12">
                                    {customError &&
                                        <Error errorMessage={customError} />
                                    }
                                </Col>
                                {!(activeInspectionDrawItems?.length > 0 && [IO_COMPLETED, IO_RECEIVED].includes(parseInt(fields.action_id)) === true) ?
                                    <Row>
                                        <Col xs="12" sm="12" md="12" lg="12">
                                            <div className="text-right">
                                                <AvGroup>
                                                    <Button size="sm" color="danger" outline onClick={() => { handleToggleModal(isOpenInspectionModal); }}>
                                                        Cancel
                                                    </Button>
                                                    <Button size="sm" color="primary" outline onClick={(e) => handleSubmit(e, false, false)}>
                                                        Submit
                                                    </Button>
                                                </AvGroup>
                                            </div>
                                        </Col>
                                    </Row> :
                                    <Row>
                                        <Col xs="12" sm="12" md="12" lg="12">
                                            <div className="text-center">
                                                {finalize ?
                                                    <>
                                                        <Button className="float-right" size="sm" color="danger" outline onClick={() => { handleToggleModal(isOpenInspectionModal); }}>
                                                            Cancel
                                                        </Button>
                                                        <Button className="float-right" size="sm" outline color="primary" onClick={(e) => handleSubmit(e, true, false)} disabled={ourReportPreviewed === false ? true : false}>
                                                            Send Our Report
                                                        </Button>
                                                        <Button className="float-right mr-0" size="sm" outline color="primary" onClick={(e) => handleSubmit(e, true, true)}>
                                                            Preview Our Report
                                                        </Button>
                                                        <Button className="float-right" size="sm" outline color="primary" onClick={(e) => handleSubmit(e, false, false)} disabled={propertyFiles.length ? false : true}>
                                                            Send Uploaded PDF
                                                        </Button>
                                                    </>
                                                    :
                                                    <>
                                                        <Button className="float-right" size="sm" outline color="primary" onClick={() => handleFinalize(false)}>
                                                            Finalize
                                                        </Button>
                                                        <Button className="float-right mr-3" outline size="sm" color="primary" onClick={() => handleFinalize(true)}>
                                                            Save Changes
                                                        </Button>
                                                    </>
                                                }

                                            </div>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="12">
                                        <h2> Inspection History </h2>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="12" style={{ 'maxHeight': "100px", overflow: "auto" }}>
                                        <Table className="align-items-center" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className="text-center" scope="col">Date-Time (MST)</th>
                                                    <th className="text-center" scope="col">Scheduled Date</th>
                                                    <th className="text-center" scope="col">Inspection Type</th>
                                                    {checkRoles(["ADMINISTRATOR", "PROCESSOR", "SALES_REP", "ACCOUNTING", "ACCOUNT_REP"]) &&
                                                        <th className="text-center" scope="col">Vendor</th>
                                                    }
                                                    <th className="text-center" scope="col">Action</th>
                                                    {userData.is_RV_user === true && <th className="text-center" scope="col">Who</th>}
                                                    <th className="text-center" scope="col">Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {inspectionHistory.length === 0 && <tr><th colSpan="8" className="text-center"> There is no history available for this report. </th></tr>}
                                                {inspectionHistory.map((inspection, i) =>
                                                    <tr key={i}>
                                                        <td className="text-center">{<DateFormat fieldName={'datetime'} row={inspection} />}</td>
                                                        <td className="text-center">
                                                            {/* {inspection.other_data ? moment(inspection.other_data.schedule_date).format('MMM D, hh:mm A') : "-"} */}
                                                            {inspection.schedule_date ? inspection.schedule_date : '-'}
                                                        </td>
                                                        <td className="text-center">
                                                            {inspection.inspection_order ? inspection.inspection_order.inspection_type.name : ""}
                                                        </td>
                                                        {checkRoles(["ADMINISTRATOR", "PROCESSOR", "SALES_REP", "ACCOUNTING", "ACCOUNT_REP"]) &&
                                                            <td className="text-center"> {inspection.inspection_order ? inspection.inspection_order.inspection_vendor.nickname : "-"} </td>
                                                        }
                                                        <td className="text-center"> {inspection.inspection_action_type.status_name} </td>
                                                        {userData.is_RV_user === true && <td className="text-center"> {inspection.user.first_name} {inspection.user.last_name} </td>}
                                                        <td className="text-center"> {inspection.question_comment} </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </AvForm>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal className="modal-dialog-centered modal-lg" style={{ maxWidth: "700px" }} isOpen={isOpenPayTripChargeModal} toggle={() => setIsOpenPayTripChargeModal(false)}>
                <div className="modal-header">
                    <h2 className="modal-title">
                        Trip Charge
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setIsOpenPayTripChargeModal(false)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="text-left">
                        <AvForm >
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12">
                                    This inspection type has ${inspectionTripCharge} as trip charge. Do you want to charge it?
                                </Col>
                                <Col xs="12" sm="12" md="12" lg="12">

                                    <br />


                                    {!paymentError && <ReportOrderOriginalSource reportOrderId={onReportData.report_order_id} inspectionOrderId={onReportData.inspection_order_id} onReportOrderOriginalSourceCB={onReportOrderOriginalSourceCB} />}


                                    {paymentError &&
                                        <Row>
                                            <span className="col-md-12 text-danger font-weight-bold">
                                                The payment was failed because of following reason. <br />
                                                <ul><li>{paymentError}</li></ul>
                                            </span >
                                        </Row>
                                    }
                                    {(selectedPaymentSource && selectedPaymentSource?.isOriginalSource === false) &&
                                        <Label><span className="text-success mr-2"><i className="ni ni-check-bold"></i> {selectedPaymentSource.selectionText}</span><i className="fa fa-pencil-alt text-danger" onClick={() => openPaymentMethodSelector()} aria-hidden="true"></i></Label>
                                    }

                                    {(!selectedPaymentSource && defaultSourceChecked) &&
                                        <Button size="sm" color="warning" outline onClick={() => openPaymentMethodSelector()}> <i className="fas fa-credit-card"></i> Select Payment Method </Button>
                                    }
                                </Col>
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <div className="text-right">
                                        <AvGroup>
                                            <Button type="button" size="sm" color="danger" outline onClick={() => setIsOpenPayTripChargeModal(false)}>
                                                Cancel
                                            </Button>
                                            <Button type="button" size="sm" color="warning" outline onClick={() => saveInspectionData(selectedRow)}>
                                                No, Submit without charge
                                            </Button>
                                            <Button type="button" size="sm" color="primary" disabled={!selectedPaymentSource ? true : false} onClick={() => handleAfterPayment()}>
                                                Yes, charge it
                                            </Button>
                                        </AvGroup>
                                    </div>
                                </Col>
                            </Row>

                        </AvForm>
                    </div>
                </div>
            </Modal>

            <PaymentForm onPay={isOpenPaymentSourceModal}
                paymentDetails={{
                    total_amount: inspectionTripCharge,
                    cc_surcharge: null,
                    paymentFor: 'Trip Charge'
                }}
                onPaymentSourceCb={handlePaymentSourceCb} clientId={clientId} />
        </>
    );
};

export default InspectionHistoryModal;
