import React, { useState, useEffect } from "react";
import { Button, CardFooter, Modal, Row, Col, CardBody, UncontrolledTooltip } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "./style.scss";
import * as moment from "moment";
import { GetData } from "services/Api.service";
import Spinner from "components/Common/Spinner.js";
import { Typeahead } from 'react-bootstrap-typeahead';
import RenderBootstrapTable from "components/Common/RenderBootstrapTable";
import { sendExceptionEmail } from "components/Common/Helpers";

const BudgetAddNewLineItem = ({ propData, handleResponseData, isOpenAddNewLineItemModal, handleAddNewLineItemPopupState }) => {
    const [isProcess, setProcess] = useState(false);
    const [defaultValue, setDefaultValue] = useState([]);
    let [modal, setModal] = useState(true);
    let [update, setUpdate] = useState(moment());
    let [category, setCategory] = useState([]);
    let [selectedCategory, setselectedCategory] = useState([]);
    let [customErrorMessage, setCustomErrorMessage] = useState(" ");
    let [customErrorMasterMessage, setCustomErrorMasterMessage] = useState({});
    let [newCategoryInput, setNewCategoryInput] = useState(" ");
    let [checkBoxStateByID, setCheckBoxStateByID] = useState([]);
    let [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState([]);
    const [errorRowId, setErrorRowId] = useState([]);
    const [key, setKey] = useState(0);

    const trClassName = (row, rowIndex) => errorRowId.includes(row.id) ? 'table-row-error' : '';

    useEffect(() => {
        try {
            setModal(isOpenAddNewLineItemModal);
            setProcess(true);
            async function fetchInitialData() {
                let result = await GetData("orders/get-budget-categories");
                setCategory(result.data.budgetCategoriesData.map((res) => res.name));
                setProcess(false);
                setselectedCategory([]);
                setCustomErrorMessage(" ");
                setDefaultValue([{ id: 1, line_item_name: "", description: "", category: [], quantity: 0, price: 0, exclude: 0, cost: 0, split_amount_heavy_repairs: 0, split_amount_deferred_maintenance: 0, split_amount_upgrades: 0, split_amount_reconfiguration_value_add: 0, split_amount_soft_costs: 0 }]);
            }
            fetchInitialData();

        } catch (errors) {
            setProcess(false);
            setselectedCategory([]);
        };

    }, [propData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let tempDefault = defaultValue;
        setDefaultValue(tempDefault);
    }, [update, defaultValue]);

    const toggle = () => {
        setModal(!modal);
    };

    let handleAddNewLineItemPopupStateFinalData = (e) => {
        setModal(!modal);
        handleAddNewLineItemPopupState(false);
    }

    let handleAddNewLineItemFinalData = async (e) => {
        try {
            let errorRowIds = []
            let errorRowMessage = {
                ...customErrorMasterMessage,
                error_one: {
                    id: '',
                    error: ''
                },
                error_two: {
                    id: '',
                    error: ''
                }
            };

            if (selectedCheckboxIds.length !== defaultValue.length && (!propData.isFromSupplementalTab)) {
                const selectedSet = new Set(selectedCheckboxIds);

                const errorRow = defaultValue
                    .map(item => item.id)
                    .filter(id => !selectedSet.has(id));

                errorRowIds = [...errorRowIds, ...errorRow];
                errorRowMessage.error_one.id = errorRow.toString();
                errorRowMessage.error_one.error = "*Select at least one class per line item (Heavy, Deferred Maintenance, Update, Value Add.)";
            }
            let tempResponseData = defaultValue;

            const errorRow = [];
            tempResponseData.forEach((data) => {
                if (!(data.line_item_name.length > 3 && data.category.length > 0 && data.cost > 0)) {
                    errorRow.push(data.id);
                }
            })
            if (errorRow.length === 0) {
                setModal(!modal);
                handleResponseData(tempResponseData);
                errorRowMessage.error_two.id = ''
                errorRowMessage.error_two.error = ''
            } else {
                errorRowIds = [...errorRowIds, ...errorRow];
                errorRowMessage.error_two.id = errorRow.toString();
                errorRowMessage.error_two.error = "*Please fill all necessary data (Line Item, Category, Cost)."
            }
            setCustomErrorMasterMessage(errorRowMessage)
            errorRowIds = [...new Set(errorRowIds)];
            setErrorRowId(errorRowIds)
        }
        catch (error) {
            await sendExceptionEmail(error);
        }
    };

    let onChangeHandle = (event, row, field) => {
        let values = defaultValue;
        let index = values.findIndex(x => x.id === row.id);
        if (index >= 0) {
            values[index][field] = event.target.value;
            setDefaultValue(values);
            setUpdate(moment());
        }
    };
    let onKeyDownAddNewCategory = (e, row) => {
        if ((e.key).toLowerCase() === "tab") {
            if ((selectedCategory).length < 3) {
                let categoryFlag = category.includes(x => x !== newCategoryInput);
                if (categoryFlag === false) {
                    let tmpCategory = [...selectedCategory, (newCategoryInput).trim()]
                    selectedCategory = tmpCategory;
                    let categoryTemp = [...category, newCategoryInput.trim()]
                    setCategory(categoryTemp);
                    onDropDownChangeHandle(tmpCategory, row);
                    setKey(key + 1);
                    setUpdate(moment());
                }
            }
        }
    };
    let onInputChangeAddNewCategory = (e) => {
        let index = category.findIndex(x => x === e);
        if (index === -1) {
            setNewCategoryInput(e);
        }
    };

    let onDropDownChangeHandle = (e, row) => {
        if (e.length === 1 && e[0] === '') {
            return;
        }

        if (e.length <= 3) {
            let tempDefault = defaultValue;
            let index = tempDefault.findIndex(x => x.id === row.id);
            if (index >= 0) {
                tempDefault[index].category = e;
                setDefaultValue(tempDefault);
                setUpdate(moment());
            }
            handleFocusOut();
        }
    };

    let textBox = (cell, row, field, type, required = false) => {
        let name = field;
        let placeholder = name
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        let validate = {};
        let minLength = 4;

        if (type === "number") {
            validate = {
                required: {
                    value: required,
                    errorMessage: "This field is required."
                },
                pattern: { value: /^[0-9\b]+$/, errorMessage: "Invalid format." },
            }
        } else {
            validate = {
                required: {
                    value: required,
                    errorMessage: "This field is required."
                },
            }
        }

        if (type === "text" && field === "line_item_name" && minLength > 0) {
            validate.minLength = {
                value: minLength,
                errorMessage: `Please input at least ${minLength} characters.`
            };
        }

        return (<AvField
            name={name + "_" + row.id}
            maxLength="250"
            autoComplete="off"
            onChange={(e) => { onChangeHandle(e, row, field) }}
            className="form-control-alternative form-control"
            placeholder={placeholder}
            type={type}
            value={row[field] + ""}
            errorMessage={customErrorMessage}
            minLength="3"
            validate={validate}
        />)
    };
    let checkbox = function (cell, row, field) {
        const budgetId = `${field}_${row.id}`;
        const toolTipData = field === "split_amount_heavy_repairs" ? row.split_amount_heavy_repairs : field === "split_amount_deferred_maintenance" ? row.split_amount_deferred_maintenance : field === "split_amount_upgrades" ? row.split_amount_upgrades : field === "split_amount_reconfiguration_value_add" ? row.split_amount_reconfiguration_value_add : field === "split_amount_soft_costs" ? row.split_amount_soft_costs : 0;
        return (
            <>
                <div name={budgetId}>
                    <input type="checkbox" id={budgetId} onChange={(e) => onCheckBoxChange(e, row, field, budgetId)} checked={(checkBoxStateByID && checkBoxStateByID.find(o => o === budgetId)) ? true : false} className="custom-checkbox" align="center" />
                    <UncontrolledTooltip delay={0} placement="auto" target={budgetId} >{parseInt(toolTipData) !== "" && parseInt(toolTipData) > 0 ? parseInt(toolTipData) : 0}%</UncontrolledTooltip>
                </div>
            </>
        );
    };
    let addNewRow = () => {
        let data1 = defaultValue;
        let data = { id: data1[defaultValue.length - 1].id + 1, line_item_name: "", description: "", category: [], exclude: 0, quantity: 0, price: 0, cost: 0, split_amount_heavy_repairs: 0, split_amount_deferred_maintenance: 0, split_amount_upgrades: 0, split_amount_reconfiguration_value_add: 0, split_amount_soft_costs: 0 }
        let values = [...defaultValue, data]
        setDefaultValue(values);
    }
    let removeRow = (e, row) => {
        let values = defaultValue;
        if (values.length > 1) {
            let selectedIds = [...selectedCheckboxIds];
            let indexSelectedIds = selectedIds.findIndex(x => x === row.id);
            let index = values.findIndex(x => x.id === row.id);
            values.splice(index, 1);
            let checkBoxStateByIDTemp = [...checkBoxStateByID];
            let checkBoxStateIDIndex = checkBoxStateByIDTemp.findIndex(x => x.endsWith("_" + row.id));
            const totalRowId = values.map(item => item.id)
            const updateErrorRow = errorRowId.filter(value => totalRowId.includes(value));
            setErrorRowId(updateErrorRow)
            if (checkBoxStateIDIndex >= 0) {
                checkBoxStateByIDTemp.splice(checkBoxStateIDIndex, 1);
                setCheckBoxStateByID(checkBoxStateByIDTemp);
                setDefaultValue(values);
                selectedIds.splice(indexSelectedIds, 1);
                setSelectedCheckboxIds(selectedIds);
            }
            setUpdate(moment());
        }
    }
    let onCheckBoxChange = (e, row, field, budgetId) => {
        let values = defaultValue;
        let selectedIds = selectedCheckboxIds;
        let indexSelectedIdsCount = selectedIds.filter(x => x === row.id).length;
        let checkBoxStateByIDTemp = checkBoxStateByID;
        let count = checkBoxStateByIDTemp.filter(x => x.endsWith("_" + row.id)).length;
        let devideValue = count;
        if (e.target.checked) {
            devideValue = count + 1;
            if (indexSelectedIdsCount === 0) {
                selectedIds = [...selectedIds, row.id];
                setSelectedCheckboxIds(selectedIds);
            }
        } else {
            devideValue = count - 1;
            if (indexSelectedIdsCount >= 1) {
                let indexSelectedIds = selectedIds.findIndex(x => x === row.id);
                selectedIds.splice(indexSelectedIds, 1);
                setSelectedCheckboxIds(selectedIds);
            }
        }

        let index = values.findIndex(x => x.id === row.id);
        values[index]['split_amount_heavy_repairs'] = checkBoxStateByIDTemp.findIndex(x => x === "split_amount_heavy_repairs_" + row.id) >= 0 && parseInt(values[index]['split_amount_heavy_repairs']) >= 0 ? Math.round(100 / devideValue) : 0;
        values[index]['split_amount_deferred_maintenance'] = checkBoxStateByIDTemp.findIndex(x => x === "split_amount_deferred_maintenance_" + row.id) >= 0 && parseInt(values[index]['split_amount_deferred_maintenance']) >= 0 ? Math.round(100 / devideValue) : 0;
        values[index]['split_amount_upgrades'] = checkBoxStateByIDTemp.findIndex(x => x === "split_amount_upgrades_" + row.id) >= 0 && parseInt(values[index]['split_amount_upgrades']) >= 0 ? Math.round(100 / devideValue) : 0;
        values[index]['split_amount_reconfiguration_value_add'] = checkBoxStateByIDTemp.findIndex(x => x === "split_amount_reconfiguration_value_add_" + row.id) >= 0 && parseInt(values[index]['split_amount_reconfiguration_value_add']) >= 0 ? Math.round(100 / devideValue) : 0;
        values[index]['split_amount_soft_costs'] = checkBoxStateByIDTemp.findIndex(x => x === "split_amount_soft_costs_" + row.id) >= 0 && parseInt(values[index]['split_amount_soft_costs']) >= 0 ? Math.round(100 / devideValue) : 0;

        if (e.target.checked) {
            if (count + 1 === 3) {
                values[index][field] = 34;
            } else {
                values[index][field] = Math.round(100 / devideValue);
            }
            checkBoxStateByIDTemp = [...checkBoxStateByID, budgetId];
            setCheckBoxStateByID(checkBoxStateByIDTemp);
            setDefaultValue(values);
        } else {
            let index = checkBoxStateByIDTemp.findIndex(x => x === budgetId);
            let valIndex = values.findIndex(x => x.id === row.id);
            if (index >= 0) {
                if (values[valIndex] !== undefined) {
                    values[valIndex][field] = 0;
                    checkBoxStateByIDTemp.splice(index, 1);
                    setCheckBoxStateByID(checkBoxStateByIDTemp);
                    if (count - 1 === 3) {
                        let reversedArray = checkBoxStateByIDTemp.slice().reverse();
                        let lastIndex = reversedArray.findIndex(x => x.endsWith("_" + row.id));

                        if (lastIndex !== -1) {
                            lastIndex = checkBoxStateByIDTemp.length - 1 - lastIndex;
                        }
                        let checboxIndex = checkBoxStateByIDTemp[lastIndex];
                        values[valIndex][checboxIndex.replace("_" + row.id, "")] = 34;
                    }
                    setDefaultValue(values);
                }
            }
        }
        setUpdate(moment());
    }
    let actionButton = (cell, row, field) => {
        return (
            <div className="lender-entity-section">
                <Button color="danger" size="sm" name="addNewLineItem" type="button" onClick={(e) => removeRow(e, row)}>
                    <span id={'action_remove_' + row.id} data-placement="auto" className="fa fa-minus-circle" aria-hidden="true" /><UncontrolledTooltip delay={0} placement="auto" target={'action_remove_' + row.id} >Remove Line Item</UncontrolledTooltip>
                </Button>
            </div>
        );
    };

    const handleFocus = (e, row) => {
        let openDropDownRow = [row.id];
        setIsDropdownOpen(openDropDownRow);
        setUpdate(moment());
    };

    const handleFocusOut = () => {
        setIsDropdownOpen([]);
        setUpdate(moment());
    };
    let dropDown = (cell, row) => {
        let dropDownSelectedCategory = row.category;
        return (
            <><Row>
                <Col className="text-center">
                    <div className="my-typeahead">
                        <Typeahead
                            multiple={true}
                            id="typeahead"
                            key={key}
                            options={category}
                            value={newCategoryInput}
                            inputText={newCategoryInput}
                            selected={dropDownSelectedCategory}
                            placeholder="Select Categories"
                            onChange={(e) => onDropDownChangeHandle(e, row)}
                            onKeyDown={(e) => onKeyDownAddNewCategory(e, row)}
                            onInputChange={(e) => onInputChangeAddNewCategory(e, row)}
                            onFocus={(e) => handleFocus(e, row)}
                            onBlur={handleFocusOut}
                            open={isDropdownOpen.length > 0 && isDropdownOpen[0] === row.id ? true : false}
                            emptyLabel={< div style={{ fontSize: 14, whiteSpace: "normal" }}>Type new category and hit tab to add new category.</div>}
                        />
                    </div>
                </Col>
            </Row>
            </>
        );
    };
    let columns = [
        { title: "No", width: "3%", isKey: true, dataField: "id", align: "center" },
        { title: "Line Item", width: "20%", dataFormat: (cell, row) => textBox(cell, row, "line_item_name", "text", true), align: "center" },
        { title: "Description", width: "20%", dataFormat: (cell, row) => textBox(cell, row, "description", "text", false), align: "center" },
        { title: "Category", width: "21%", dataFormat: (cell, row) => dropDown(cell, row), align: "center" },
        { title: "Quantity", width: "7%", dataFormat: (cell, row) => textBox(cell, row, "quantity", "number", false), align: "center" },
        { title: "Price", width: "5%", dataFormat: (cell, row) => textBox(cell, row, "price", "number", false), align: "center" },
        { title: "Cost", width: "5%", dataFormat: (cell, row) => textBox(cell, row, "cost", "number", true), align: "center" }
    ];
    if (!propData.isFromSupplementalTab) {
        columns.push({ title: `H`, width: "3%", dataFormat: (cell, row) => checkbox(cell, row, "split_amount_heavy_repairs"), align: "center", hasTooltip: true, tooltipText: "Heavy Repairs" },
            { title: `D`, width: "3%", dataFormat: (cell, row) => checkbox(cell, row, "split_amount_deferred_maintenance"), align: "center", hasTooltip: true, tooltipText: "Deferred Mainenance" },
            { title: `U`, width: "3%", dataFormat: (cell, row) => checkbox(cell, row, "split_amount_upgrades"), align: "center", hasTooltip: true, tooltipText: "Upgrades" },
            { title: `VA`, width: "3%", dataFormat: (cell, row) => checkbox(cell, row, "split_amount_reconfiguration_value_add"), align: "center", hasTooltip: true, tooltipText: "Value Add / Reconfigurations" },
            { title: `SC`, width: "3%", dataFormat: (cell, row) => checkbox(cell, row, "split_amount_soft_costs"), align: "center", hasTooltip: true, tooltipText: "Soft Costs" })
    }
    columns.push({ title: `Action`, width: "10%", dataFormat: (cell, row) => actionButton(cell, row), align: "center" });

    const tableParams = {
        tableData: defaultValue,
        columns,
        trClassName
    };
    const subjectPropertyTable = (
        <>
            <div className="p-12">
                <AvForm>
                    <CardBody className="budget-section" style={{ maxHeight: "450px" }}>
                        <Row>
                            <Col >
                                <RenderBootstrapTable tableParams={tableParams} pagination={false} />
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col className="text-left">
                                <Button color="info" size="sm" type="button" onClick={(e) => addNewRow()}>
                                    <i className="p-2 fa fa-plus-circle" aria-hidden="true"></i> Add New Line Item
                                </Button>
                            </Col>
                            <Col className="text-right">
                                <div className="text-right">
                                    {customErrorMasterMessage?.error_one?.id && <div> <span><b>Row Number</b> : {customErrorMasterMessage.error_one.id}, - </span><span className="form-error">{customErrorMasterMessage.error_one.error}</span></div>}
                                    {customErrorMasterMessage?.error_two?.id && <div><span><b>Row Number</b> : {customErrorMasterMessage.error_two.id}, - </span><span className="form-error">{customErrorMasterMessage.error_two.error}</span></div>}
                                </div>
                                <Button color="info" outline onClick={(e) => handleAddNewLineItemFinalData(e)}>
                                    Save And Close
                                </Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </AvForm>
            </div>
        </>
    );
    return (
        <>
            <Modal keyboard={false} backdrop="static" className="modal-dialog-centered neighborhood-land-user" style={{ maxWidth: "1600px" }} isOpen={modal} toggle={() => toggle()}>
                <Spinner isShow={isProcess} />
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100" id="budgetAllocateModalLabel"> Add New Budget Line Item </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleAddNewLineItemPopupStateFinalData()}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                {subjectPropertyTable}
            </Modal>
        </>
    );
};
export default BudgetAddNewLineItem;